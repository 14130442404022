@keyframes OdtO4q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OdtO4q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OdtO4q_textField {
  width: 100%;
}

.OdtO4q_container {
  margin: 24px 0;
}

@keyframes zyS_Na_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zyS_Na_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zyS_Na_switch {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.zyS_Na_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.zyS_Na_switch .zyS_Na_slider {
  cursor: pointer;
  background-color: var(--color-neutral-90);
  border-radius: 12px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.zyS_Na_switch .zyS_Na_slider:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
  box-shadow: 0 3px 7px #0000001f;
}

.zyS_Na_switch input:checked + .zyS_Na_slider {
  background-color: var(--color-success-70);
}

.zyS_Na_switch input:checked + .zyS_Na_slider:before {
  transform: translateX(16px);
}

.zyS_Na_wrapper {
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  display: flex;
}

.zyS_Na_wrapper .zyS_Na_label {
  font: var(--font-body-2);
  flex: 1;
  margin-right: 8px;
}

.zyS_Na_wrapper.zyS_Na_error {
  border-color: var(--color-error);
}

.zyS_Na_wrapper.zyS_Na_error:focus-within {
  outline-color: var(--color-danger-focused);
}

/*# sourceMappingURL=README.15879e6e.css.map */
